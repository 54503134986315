// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app\\entry.client.tsx"
);
import.meta.hot.lastModified = "1729652562547.0652";
}
// REMIX HMR END

import { RemixBrowser } from '@remix-run/react'
import { startTransition } from 'react'
import { hydrateRoot } from 'react-dom/client'

startTransition(() => {
	hydrateRoot(document, <RemixBrowser />)
})
